<template>
  <div class="question">
    <div class="question-c">
      <div class="title">
        <div class="title-l">
          {{ currentData.title }}({{
            currentData.quType == 1 ? "单选题" : `多选题`
          }})
        </div>
        <div class="title-r">{{ currentIndex + 1 }}/{{ postData.length }}</div>
      </div>
      <div class="main">
        <div class="imgMain" v-if="currentData.imgList">
          <div
            class="img"
            v-for="(item, index) in currentData.imgList"
            :key="index"
          >
            <img :src="item" alt="" />
          </div>
        </div>
        <div class="txtMain">
          <div
            v-for="(item, index) in currentData.optionList"
            :class="[
              'txt',
              { isSelect: item.isSelect },
              { right: item.rightClass },
              { false: item.falseClass },
            ]"
            :key="index"
            @click="selectOps(item)"
          >
            {{ item.optionTitle }}
          </div>
        </div>
      </div>
      <div class="right" v-if="currentData.isshow">
        <div class="right-l">正确答案：</div>
        <div class="right-r">{{ returnRightAns() }}</div>
      </div>

      <div
        class="btn"
        @click="toComplete"
        v-if="currentIndex + 1 == postData.length"
      >
        完成
      </div>
      <div class="btn" @click="toNext" v-else>下一题</div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { toRegister, gloabalCount } from "@/utils/common.js";
import { questionListUrl, submitQuestionUrl, rubTopicInfoUrl } from "./api.js";
var moment = require("moment");
export default {
  name: "answerRecord",
  data() {
    return {
      isDialog: true,
      postData: [],
      currentIndex: 0,
      currentData: {
        title: "",
        imgList: [],
        isshow: false,
      },
      code: "",
      answerList: [],
      id: "",
      indexData: {},
      timer: null,
    };
  },
  components: {},
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.code = this.$route.query.code;
  },
  async mounted() {
    // gloabalCount("", 20, 1);
    await this.getIndexData();
    this.getQuestionList();
  },
  methods: {
    async getIndexData() {
      let res = await this.$axios.get(rubTopicInfoUrl, {
        params: {
          houseId: this.roomId || undefined,
          userId: this.userId,
        },
      });
      if (res.code === 200) {
        this.indexData = res.data;
      } else {
      }
    },
    async postQuestionnaire() {
      let params = {
        answerList: this.answerList,
        houseId: this.roomId || undefined,
        topicId: this.indexData.id,
        userId: this.userId,
      };
      let res = await this.$axios.post(`${submitQuestionUrl}`, params);
      return res;
    },
    async toComplete() {
      if (this.userId && this.userId != 0) {
        let arr = [];
        this.currentData.optionList.forEach((ele) => {
          if (ele.isSelect) {
            arr.push(ele.isSelect);
          }
        });
        if (arr.length <= 0) {
          this.$toast("请先完成当前题目！");
          return;
        }
        this.currentData.optionList.forEach((ele) => {
          if (this.currentData.rightAnswer.indexOf(ele.optionKey) != -1) {
            ele.rightClass = true;
          } else {
            if (ele.isSelect) {
              ele.falseClass = true;
            } else {
            }
          }
        });
        console.log(
          this.currentData.optionList,
          "===",
          this.currentData.rightAnswer
        );
        this.judgeAnswer();
        this.timer = setTimeout(async () => {
          if (this.code == 2) {
            let res = await this.postQuestionnaire();
            if (res.code == 200) {
              this.$router.replace({
                name: "answerRecord",
                query: {
                  id: this.indexData.id,
                },
              });
            }
          } else if (this.code == 3) {
            wx.miniProgram.navigateTo({
              url: `/pages/index/rubIndex`,
            });
          }

          clearTimeout(this.timer);
        }, 1000);
      } else {
        wx.miniProgram.navigateTo({
          url: `/pages/index/rubIndex`,
        });
      }
      this.$forceUpdate();
    },
    judgeAnswer() {
      let arr = [];
      this.currentData.optionList.forEach((ele) => {
        if (ele.isSelect) {
          arr.push(ele.optionKey);
        }
      });
      this.answerList.push({
        id: this.currentData.id,
        answer: arr.join(","),
      });
    },
    toNext() {
      let arr = [];
      this.currentData.optionList.forEach((ele) => {
        if (ele.isSelect) {
          arr.push(ele.isSelect);
        }
      });
      if (arr.length <= 0) {
        this.$toast("请先完成当前题目！");
        return;
      }
      this.currentData.optionList.forEach((ele) => {
        if (this.currentData.rightAnswer.indexOf(ele.optionKey) != -1) {
          ele.rightClass = true;
        } else {
          if (ele.isSelect) {
            ele.falseClass = true;
          } else {
          }
        }
      });
      this.judgeAnswer();
      this.timer = setTimeout(() => {
        if (this.currentIndex < this.postData.length - 1) {
          this.currentIndex++;
          this.currentData = this.postData[this.currentIndex];
        }
        clearTimeout(this.timer);
      }, 1000);

      this.$forceUpdate();
    },
    returnRightAns() {
      let str = "";
      let arr = [];
      this.currentData.optionList.forEach((ele) => {
        if (this.currentData.rightAnswer.indexOf(ele.optionKey) != -1) {
          arr.push(ele.optionTitle);
        }
      });
      return arr.join(",");
    },
    selectOps(item) {
      if (this.currentData.quType == 1) {
        this.currentData.optionList.forEach((ele) => {
          ele.isSelect = false;
        });
        item.isSelect = true;
      } else {
        item.isSelect = !item.isSelect;
      }

      this.$forceUpdate();
    },

    async getQuestionList() {
      let params = {
        quCount: 3,
      };
      let res = await this.$axios.get(`${questionListUrl}`, { params: params });
      console.log(res, 333);
      if (res.code === 200) {
        this.postData = res.data;
        this.postData.forEach((element) => {
          if (element.imageUrls) {
            element.imgList = element.imageUrls.split(",");
          } else {
            element.imgList = [];
          }
          element.rightOps = [];
          element.optionList.forEach((ele) => {
            ele.isSelect = false;
            ele.rightClass = false;
            ele.falseClass = false;
            ele.isClick = true;
          });
        });
        this.currentData = this.postData[0];
      }
    },
    toClose() {
      this.isDialog = false;
    },
  },
};
</script>
<style lang="less" scoped>
.question {
  min-height: 100vh;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .question-c {
    padding: 0 32px;
    .title {
      display: flex;
      justify-content: space-between;
      font-size: 32px;
      font-weight: 600;
      color: #1a1c34;
      line-height: 44px;
      padding: 44px 0;
      align-items: center;
      .title-l {
        width: 500px;
      }
    }
    .main {
      .imgMain {
        padding: 0 0 44px 0;
        .img {
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
      .txtMain {
        .txt {
          background: #d9d9d9;
          border-radius: 8px;
          padding: 14px 0;
          text-align: center;
          margin-bottom: 40px;
        }
        .isSelect {
          background: rgba(0, 0, 0, 0.7);
          color: #fff;
        }
        .right {
          background: #9ac561;
          color: #fff;
        }
        .false {
          background: #d53737;
          color: #fff;
        }
      }
    }
    .right {
      .right-l,
      .right-r {
        display: inline-block;
        font-size: 28px;
        font-weight: 400;
        color: #1a1c34;
        line-height: 40px;
      }
      .right-r {
        color: rgba(154, 197, 97, 1);
      }
    }
    .btn {
      font-size: 32px;
      text-align: center;
      font-weight: 400;
      color: #ffffff;
      line-height: 52px;
      margin-top: 60px;
      background: #2e5da2;
      border-radius: 8px;
      padding: 14px 0;
    }
  }
}
</style>
<style lang="less">
.question {
}
</style>
